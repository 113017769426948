import React, { useEffect, useState } from 'react'
import Icons from '../../assets/icons'
import placeHolderImg from '../../assets/images/item-placeholder.png'
import noPost from '../../assets/images/no-post.png'
import Spinner from '../../components/Spinner'
import SidePanel from '../../layouts/Sidepanel'
import Api from '../../services/Api'

const ContentModerator = () => {
  const [searchValue, setSearchValue] = useState('')
  const [debouncedSearchValue, setDebouncedSearchValue] = useState('')
  const [items, setItems] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const searchPost = async () => {
    if (!debouncedSearchValue.trim()) return

    try {
      setIsLoading(true)
      const response = await Api.getPosts({
        search: debouncedSearchValue,
        sort: 'price_low_to_high',
      })

      setItems(response.data)
      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching posts:', error)
    }
  }

  const handleInputChange = event => {
    setSearchValue(event.target.value)
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValue(searchValue)
    }, 500)

    return () => clearTimeout(handler)
  }, [searchValue])

  const formatTimestampToDate = timestamp => {
    if (!timestamp || typeof timestamp._seconds !== 'number') {
      throw new Error('Invalid timestamp object')
    }

    const date = new Date(timestamp._seconds * 1000)

    return date.toISOString().split('T')[0]
  }

  const formatTimestampToTime = timestamp => {
    if (!timestamp || typeof timestamp._seconds !== 'number') {
      throw new Error('Invalid timestamp object')
    }
    const date = new Date(timestamp._seconds * 1000)

    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')

    return `${hours}:${minutes}:${seconds}`
  }

  const archivePost = async id => {
    setIsLoading(true)
    const response = await Api.archivePost({ pid: id })
    if (!response.success) throw new Error(response.message)
    setIsLoading(false)

    // Refresh List
    setTimeout(() => {
      searchPost()
    }, 1000)
  }

  useEffect(() => {
    searchPost()
  }, [debouncedSearchValue])

  const renderPost = (post, index) => (
    <div key={index} className="post-card">
      <div className="post-info">
        <div className="post-image">
          <img src={placeHolderImg} alt="" />
        </div>
        <div className="post-details">
          <div className="post-title">
            <span>{post.title}</span>
            <span className="verified">✓</span>
          </div>
          <p className="post-description">{post.description}</p>
        </div>
      </div>
      <div className="author-info">
        {/* <div className="author-avatar">
          <img src={userPlaceholderImg} alt={post.user.full_name} />
          <div className="avatar-placeholder">{post.user.full_name}</div>
        </div> */}
        <div className="author-details">
          <div className="author-name">
            <span>{post.user.full_name}</span>
          </div>
          <div className="author-handle">{post.user.display_name}</div>
        </div>
      </div>
      <div className="post-date">
        <div className="post-date-main">
          {formatTimestampToDate(post.date_posted)}
        </div>
        <div className="post-date-sub">
          {formatTimestampToTime(post.date_posted)}
        </div>
      </div>
      <div className="actions">
        <button className="archive-btn" onClick={() => archivePost(post.id)}>
          <Icons.Archive className="archive-icon" /> Archive
        </button>
        <button className="warning-btn">
          <Icons.Warning className="warning-icon" /> Suspend
        </button>
      </div>
    </div>
  )

  return (
    <div className="content-moderator">
      <SidePanel />
      <div className="content">
        <div className="datatable-wrapper">
          <div className="datatable-header">
            <div className="datatable-header-label-wrapper">
              <span className="datatable-header-label">Content Moderation</span>
            </div>
            <div className="search-wrapper">
              <Icons.Search className="search-icon" />
              <input
                type="search"
                placeholder="Search"
                value={searchValue}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>

        <div className="content-wrapper">
          <div className="posts-header">
            <div>Post</div>
            <div>Posted by</div>
            <div>Date Posted</div>
            <div className="posts-header-actions">Actions</div>
          </div>
          {isLoading ? (
            <div className="spinner">
              <Spinner type="Oval" color="#ffd400" height={40} width={40} />
            </div>
          ) : items.length !== 0 ? (
            <div className="posts-list">
              {items.map((post, index) => renderPost(post, index))}
            </div>
          ) : (
            <div className="no-post">
              <img src={noPost} alt="" />
              <div className="flag-title">No flagged posts available</div>
              <div className="flag-desc">
                Search post/s you need to manage. You also can add keywords here
                to automatically flag posts that may contain illegal products
                and services.
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ContentModerator
