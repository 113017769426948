import React from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'

import { ContextProvider } from './context'

import ContentModerator from './pages/admin/ContentModerator'
import Dashboard from './pages/admin/Dashboard'
import Login from './pages/admin/Login'
import Notifications from './pages/admin/Notifications'
import PayoutDetails from './pages/admin/payout-details'
import PayoutHistory from './pages/admin/payout-history'
import Payouts from './pages/admin/payouts'
import RegisterRider from './pages/admin/RegisterRider'
import TransactionDetails from './pages/admin/transaction-details'
import Transactions from './pages/admin/transactions'
import PageNotFound from './pages/PageNotFound'
import { firebaseInit } from './utils/firebase'
import PrivateRoutes from './utils/PrivateRoutes'

export const firebaseApp = firebaseInit()

function App() {
  return (
    <div className="App">
      <ContextProvider>
        <Router>
          <Routes>
            <Route element={<PrivateRoutes />}>
              <Route
                element={<Dashboard title="Verify users" />}
                path="/admin/dashboard"
                exact={false}
              />
              <Route
                element={<ContentModerator title="Content Moderator" />}
                path="/admin/content-moderation"
                exact={false}
              />
              <Route
                element={<Transactions title="Transactions" />}
                path="/admin/transactions"
                exact={false}
              />
              <Route
                element={<TransactionDetails title="Transaction Details" />}
                path="/admin/transactions/:id"
                exact={false}
              />
              <Route
                element={<Payouts title="Payouts" />}
                path="/admin/payouts"
                exact={false}
              />
              <Route
                element={<PayoutDetails title="Payout Details" />}
                path="/admin/payouts/:id"
                exact={false}
              />
              <Route
                element={<PayoutHistory title="Payout History" />}
                path="/admin/payout-history"
                exact={false}
              />
              <Route
                element={<Notifications title="Notifications" />}
                path="/admin/notifications"
                exact={false}
              />
              <Route
                element={<RegisterRider title="Register Rider" />}
                path="/admin/register-rider"
                exact={false}
              />
            </Route>
            <Route element={<Login />} path="/login" />
            <Route element={<PageNotFound />} path="*" />
          </Routes>
        </Router>
      </ContextProvider>
    </div>
  )
}

export default App
